@import "~Styles/colors.module";
@import "~Styles/mixins.module";

.user_pic {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: top;  
  position: absolute;
  right: 0;
  transform-origin: top right;

  border-radius: 50%;
  z-index: 2;
}
.user_pic_clickable {
  cursor: pointer;
}


@include medium {
  .user_pic {
    width: 150px;
    height: 150px;  
  }
}
@import '~Styles/mixins.module';
@import '~Styles/colors.module';

.right_container {
  overflow: hidden;
  z-index: 3;
  padding-left: 200px;

  .slide_container {
    position: relative;
    align-self: stretch;
    height: 400px;
    padding: 50px 0px 50px 260px;
  }

  .slide_inner_container {
    align-self: stretch;
    overflow-x: hidden;
    height: 400px;
    padding: 50px 0px 50px 260px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .left_button_container {
    margin: 0px 10px;
  }
}
    

.bottom_background_gradient {
  position: absolute;
  height: 150px;
  left:0;
  bottom: 0;
  right: 0;

  background-image: linear-gradient(to top, rgba($color-dark, 0.5), rgba(0, 0, 0, 0));
  border-radius: 12px;
}
